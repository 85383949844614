:root {
  --ion-color-fiveten-blue-700: #0e2a87;
  --ion-color-fiveten-blue-700-rgb: 14, 42, 135;
  --ion-color-fiveten-blue-700-contrast: #ffffff;
  --ion-color-fiveten-blue-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-blue-700-shade: #0c2577;
  --ion-color-fiveten-blue-700-tint: #263f93;
}

.ion-color-fiveten-blue-700 {
  --ion-color-base: var(--ion-color-fiveten-blue-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-blue-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-blue-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-blue-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-blue-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-blue-700-tint);
}
