:root {
  --ion-color-fiveten-neutral-700: #49515b;
  --ion-color-fiveten-neutral-700-rgb: 73, 81, 91;
  --ion-color-fiveten-neutral-700-contrast: #ffffff;
  --ion-color-fiveten-neutral-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-neutral-700-shade: #404750;
  --ion-color-fiveten-neutral-700-tint: #5b626b;
}

.ion-color-fiveten-neutral-700 {
  --ion-color-base: var(--ion-color-fiveten-neutral-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-neutral-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-neutral-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-neutral-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-neutral-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-neutral-700-tint);
}
