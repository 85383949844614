:root {
  --ion-color-fiveten-blue-100: #e9f2ff;
  --ion-color-fiveten-blue-100-rgb: 233, 242, 255;
  --ion-color-fiveten-blue-100-contrast: #000000;
  --ion-color-fiveten-blue-100-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-blue-100-shade: #cdd5e0;
  --ion-color-fiveten-blue-100-tint: #ebf3ff;
}

.ion-color-fiveten-blue-100 {
  --ion-color-base: var(--ion-color-fiveten-blue-100);
  --ion-color-base-rgb: var(--ion-color-fiveten-blue-100-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-blue-100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-blue-100-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-blue-100-shade);
  --ion-color-tint: var(--ion-color-fiveten-blue-100-tint);
}
