:root {
  --ion-color-fiveten-yellow-100: #ffff01;
  --ion-color-fiveten-yellow-100-rgb: 255, 255, 1;
  --ion-color-fiveten-yellow-100-contrast: #000000;
  --ion-color-fiveten-yellow-100-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-yellow-100-shade: #e0e001;
  --ion-color-fiveten-yellow-100-tint: #ffff1a;
}

.ion-color-fiveten-yellow-100 {
  --ion-color-base: var(--ion-color-fiveten-yellow-100);
  --ion-color-base-rgb: var(--ion-color-fiveten-yellow-100-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-yellow-100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-yellow-100-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-yellow-100-shade);
  --ion-color-tint: var(--ion-color-fiveten-yellow-100-tint);
}
