@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@use 'ibf-colors';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

// custom font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

:root {
  --ion-font-family: 'Montserrat', sans-serif;

  --ion-color-ibf-primary: var(--ion-color-ibf-trigger-alert-primary);
  --ion-color-ibf-primary-rgb: var(--ion-color-ibf-trigger-alert-primary-rgb);
  --ion-color-ibf-primary-contrast: var(
    --ion-color-ibf-trigger-alert-primary-contrast
  );
  --ion-color-ibf-primary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-primary-contrast-rgb
  );
  --ion-color-ibf-primary-shade: var(
    --ion-color-ibf-trigger-alert-primary-shade
  );
  --ion-color-ibf-primary-tint: var(--ion-color-ibf-trigger-alert-primary-tint);

  --ion-color-ibf-secondary: var(--ion-color-ibf-trigger-alert-secondary);
  --ion-color-ibf-secondary-rgb: var(
    --ion-color-ibf-trigger-alert-secondary-rgb
  );
  --ion-color-ibf-secondary-contrast: var(
    --ion-color-ibf-trigger-alert-secondary-contrast
  );
  --ion-color-ibf-secondary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-secondary-contrast-rgb
  );
  --ion-color-ibf-secondary-shade: var(
    --ion-color-ibf-trigger-alert-secondary-shade
  );
  --ion-color-ibf-secondary-tint: var(
    --ion-color-ibf-trigger-alert-secondary-tint
  );

  --ion-color-ibf-tertiary: var(--ion-color-ibf-trigger-alert-tertiary);
  --ion-color-ibf-tertiary-rgb: var(--ion-color-ibf-trigger-alert-tertiary-rgb);
  --ion-color-ibf-tertiary-contrast: var(
    --ion-color-ibf-trigger-alert-tertiary-contrast
  );
  --ion-color-ibf-tertiary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-tertiary-contrast-rgb
  );
  --ion-color-ibf-tertiary-shade: var(
    --ion-color-ibf-trigger-alert-tertiary-shade
  );
  --ion-color-ibf-tertiary-tint: var(
    --ion-color-ibf-trigger-alert-tertiary-tint
  );

  --ion-color-ibf-chat-not-self: var(--ion-color-ibf-tertiary);
  --ion-color-ibf-chat-not-self-rgb: var(--ion-color-ibf-tertiary-rgb);
  --ion-color-ibf-chat-not-self-contrast: var(
    --ion-color-ibf-tertiary-contrast
  );
  --ion-color-ibf-chat-not-self-contrast-rgb: var(
    --ion-color-ibf-tertiary-contrast-rgb
  );
  --ion-color-ibf-chat-not-self-shade: var(--ion-color-ibf-tertiary-shade);
  --ion-color-ibf-chat-not-self-tint: var(--ion-color-ibf-tertiary-tint);

  --ion-color-ibf-chat-self: var(--ion-color-ibf-tertiary);
  --ion-color-ibf-chat-self-rgb: var(--ion-color-ibf-tertiary-rgb);
  --ion-color-ibf-chat-self-contrast: var(--ion-color-ibf-tertiary-contrast);
  --ion-color-ibf-chat-self-contrast-rgb: var(
    --ion-color-ibf-tertiary-contrast-rgb
  );
  --ion-color-ibf-chat-self-shade: var(--ion-color-ibf-tertiary-shade);
  --ion-color-ibf-chat-self-tint: var(--ion-color-ibf-tertiary-tint);

  --ion-color-ibf-avatar-bg: var(--ion-color-ibf-grey);
  --ion-color-ibf-avatar-text: var(--ion-color-ibf-white);

  // Set all colors default to trigger if nothing is set ..
  --ion-color-ibf-primary: var(--ion-color-ibf-trigger-alert-primary);
  --ion-color-ibf-primary-rgb: var(--ion-color-ibf-trigger-alert-primary-rgb);
  --ion-color-ibf-primary-contrast: var(
    --ion-color-ibf-trigger-alert-primary-contrast
  );
  --ion-color-ibf-primary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-primary-contrast-rgb
  );
  --ion-color-ibf-primary-shade: var(
    --ion-color-ibf-trigger-alert-primary-shade
  );
  --ion-color-ibf-primary-tint: var(--ion-color-ibf-trigger-alert-primary-tint);

  --ion-color-ibf-secondary: var(--ion-color-ibf-trigger-alert-secondary);
  --ion-color-ibf-secondary-rgb: var(
    --ion-color-ibf-trigger-alert-secondary-rgb
  );
  --ion-color-ibf-secondary-contrast: var(
    --ion-color-ibf-trigger-alert-secondary-contrast
  );
  --ion-color-ibf-secondary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-secondary-contrast-rgb
  );
  --ion-color-ibf-secondary-shade: var(
    --ion-color-ibf-trigger-alert-secondary-shade
  );
  --ion-color-ibf-secondary-tint: var(
    --ion-color-ibf-trigger-alert-secondary-tint
  );

  --ion-color-ibf-tertiary: var(--ion-color-ibf-trigger-alert-tertiary);
  --ion-color-ibf-tertiary-rgb: var(--ion-color-ibf-trigger-alert-tertiary-rgb);
  --ion-color-ibf-tertiary-contrast: var(
    --ion-color-ibf-trigger-alert-tertiary-contrast
  );
  --ion-color-ibf-tertiary-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-tertiary-contrast-rgb
  );
  --ion-color-ibf-tertiary-shade: var(
    --ion-color-ibf-trigger-alert-tertiary-shade
  );
  --ion-color-ibf-tertiary-tint: var(
    --ion-color-ibf-trigger-alert-tertiary-tint
  );

  --ion-color-ibf-chat-not-self: var(--ion-color-ibf-tertiary);
  --ion-color-ibf-chat-not-self-rgb: var(--ion-color-ibf-tertiary-rgb);
  --ion-color-ibf-chat-not-self-contrast: var(
    --ion-color-ibf-tertiary-contrast
  );
  --ion-color-ibf-chat-not-self-contrast-rgb: var(
    --ion-color-ibf-tertiary-contrast-rgb
  );
  --ion-color-ibf-chat-not-self-shade: var(--ion-color-ibf-tertiary-shade);
  --ion-color-ibf-chat-not-self-tint: var(--ion-color-ibf-tertiary-tint);

  --ion-color-ibf-chat-self: var(--ion-color-ibf-tertiary);
  --ion-color-ibf-chat-self-rgb: var(--ion-color-ibf-tertiary-rgb);
  --ion-color-ibf-chat-self-contrast: var(--ion-color-ibf-tertiary-contrast);
  --ion-color-ibf-chat-self-contrast-rgb: var(
    --ion-color-ibf-tertiary-contrast-rgb
  );
  --ion-color-ibf-chat-self-shade: var(--ion-color-ibf-tertiary-shade);
  --ion-color-ibf-chat-self-tint: var(--ion-color-ibf-tertiary-tint);

  --ion-color-ibf-avatar-bg: var(--ion-color-ibf-grey);
  --ion-color-ibf-avatar-text: var(--ion-color-ibf-white);

  // Overwrite with no-trigger if no trigger ..
  .no-alert {
    /** base colors **/
    --ion-color-ibf-primary: var(--ion-color-ibf-no-alert-primary);
    --ion-color-ibf-primary-rgb: var(--ion-color-ibf-no-alert-primary-rgb);
    --ion-color-ibf-primary-contrast: var(
      --ion-color-ibf-no-alert-primary-contrast
    );
    --ion-color-ibf-primary-contrast-rgb: var(
      --ion-color-ibf-no-alert-primary-contrast-rgb
    );
    --ion-color-ibf-primary-shade: var(--ion-color-ibf-no-alert-primary-shade);
    --ion-color-ibf-primary-tint: var(--ion-color-ibf-no-alert-primary-tint);

    --ion-color-ibf-secondary: var(--ion-color-ibf-no-alert-secondary);
    --ion-color-ibf-secondary-rgb: var(--ion-color-ibf-no-alert-secondary-rgb);
    --ion-color-ibf-secondary-contrast: var(
      --ion-color-ibf-no-alert-secondary-contrast
    );
    --ion-color-ibf-secondary-contrast-rgb: var(
      --ion-color-ibf-no-alert-secondary-contrast-rgb
    );
    --ion-color-ibf-secondary-shade: var(
      --ion-color-ibf-no-alert-secondary-shade
    );
    --ion-color-ibf-secondary-tint: var(
      --ion-color-ibf-no-alert-secondary-tint
    );

    --ion-color-ibf-tertiary: var(--ion-color-ibf-no-alert-tertiary);
    --ion-color-ibf-tertiary-rgb: var(--ion-color-ibf-no-alert-tertiary-rgb);
    --ion-color-ibf-tertiary-contrast: var(
      --ion-color-ibf-no-alert-tertiary-contrast
    );
    --ion-color-ibf-tertiary-contrast-rgb: var(
      --ion-color-ibf-no-alert-tertiary-contrast-rgb
    );
    --ion-color-ibf-tertiary-shade: var(
      --ion-color-ibf-no-alert-tertiary-shade
    );
    --ion-color-ibf-tertiary-tint: var(--ion-color-ibf-no-alert-tertiary-tint);

    --ion-color-ibf-chat-not-self: var(--ion-color-ibf-tertiary);
    --ion-color-ibf-chat-not-self-rgb: var(--ion-color-ibf-tertiary-rgb);
    --ion-color-ibf-chat-not-self-contrast: var(
      --ion-color-ibf-tertiary-contrast
    );
    --ion-color-ibf-chat-not-self-contrast-rgb: var(
      --ion-color-ibf-tertiary-contrast-rgb
    );
    --ion-color-ibf-chat-not-self-shade: var(--ion-color-ibf-tertiary-shade);
    --ion-color-ibf-chat-not-self-tint: var(--ion-color-ibf-tertiary-tint);

    --ion-color-ibf-chat-self: var(--ion-color-ibf-tertiary);
    --ion-color-ibf-chat-self-rgb: var(--ion-color-ibf-tertiary-rgb);
    --ion-color-ibf-chat-self-contrast: var(--ion-color-ibf-tertiary-contrast);
    --ion-color-ibf-chat-self-contrast-rgb: var(
      --ion-color-ibf-tertiary-contrast-rgb
    );
    --ion-color-ibf-chat-self-shade: var(--ion-color-ibf-tertiary-shade);
    --ion-color-ibf-chat-self-tint: var(--ion-color-ibf-tertiary-tint);

    --ion-color-ibf-avatar-bg: var(--ion-color-ibf-primary);
    --ion-color-ibf-avatar-text: var(--ion-color-ibf-white);
  }

  // Overwrite with trigger if trigger ..
  .trigger-alert {
    --ion-color-ibf-primary: var(--ion-color-ibf-trigger-alert-primary);
    --ion-color-ibf-primary-rgb: var(--ion-color-ibf-trigger-alert-primary-rgb);
    --ion-color-ibf-primary-contrast: var(
      --ion-color-ibf-trigger-alert-primary-contrast
    );
    --ion-color-ibf-primary-contrast-rgb: var(
      --ion-color-ibf-trigger-alert-primary-contrast-rgb
    );
    --ion-color-ibf-primary-shade: var(
      --ion-color-ibf-trigger-alert-primary-shade
    );
    --ion-color-ibf-primary-tint: var(
      --ion-color-ibf-trigger-alert-primary-tint
    );

    --ion-color-ibf-secondary: var(--ion-color-ibf-trigger-alert-secondary);
    --ion-color-ibf-secondary-rgb: var(
      --ion-color-ibf-trigger-alert-secondary-rgb
    );
    --ion-color-ibf-secondary-contrast: var(
      --ion-color-ibf-trigger-alert-secondary-contrast
    );
    --ion-color-ibf-secondary-contrast-rgb: var(
      --ion-color-ibf-trigger-alert-secondary-contrast-rgb
    );
    --ion-color-ibf-secondary-shade: var(
      --ion-color-ibf-trigger-alert-secondary-shade
    );
    --ion-color-ibf-secondary-tint: var(
      --ion-color-ibf-trigger-alert-secondary-tint
    );

    --ion-color-ibf-tertiary: var(--ion-color-ibf-trigger-alert-tertiary);
    --ion-color-ibf-tertiary-rgb: var(
      --ion-color-ibf-trigger-alert-tertiary-rgb
    );
    --ion-color-ibf-tertiary-contrast: var(
      --ion-color-ibf-trigger-alert-tertiary-contrast
    );
    --ion-color-ibf-tertiary-contrast-rgb: var(
      --ion-color-ibf-trigger-alert-tertiary-contrast-rgb
    );
    --ion-color-ibf-tertiary-shade: var(
      --ion-color-ibf-trigger-alert-tertiary-shade
    );
    --ion-color-ibf-tertiary-tint: var(
      --ion-color-ibf-trigger-alert-tertiary-tint
    );

    --ion-color-ibf-chat-not-self: var(--ion-color-ibf-tertiary);
    --ion-color-ibf-chat-not-self-rgb: var(--ion-color-ibf-tertiary-rgb);
    --ion-color-ibf-chat-not-self-contrast: var(
      --ion-color-ibf-tertiary-contrast
    );
    --ion-color-ibf-chat-not-self-contrast-rgb: var(
      --ion-color-ibf-tertiary-contrast-rgb
    );
    --ion-color-ibf-chat-not-self-shade: var(--ion-color-ibf-tertiary-shade);
    --ion-color-ibf-chat-not-self-tint: var(--ion-color-ibf-tertiary-tint);

    --ion-color-ibf-chat-self: var(--ion-color-ibf-tertiary);
    --ion-color-ibf-chat-self-rgb: var(--ion-color-ibf-tertiary-rgb);
    --ion-color-ibf-chat-self-contrast: var(--ion-color-ibf-tertiary-contrast);
    --ion-color-ibf-chat-self-contrast-rgb: var(
      --ion-color-ibf-tertiary-contrast-rgb
    );
    --ion-color-ibf-chat-self-shade: var(--ion-color-ibf-tertiary-shade);
    --ion-color-ibf-chat-self-tint: var(--ion-color-ibf-tertiary-tint);

    --ion-color-ibf-avatar-bg: var(--ion-color-ibf-grey);
    --ion-color-ibf-avatar-text: var(--ion-color-ibf-white);
  }

  /** ----------------------------------- **/

  ion-button {
    text-transform: none;
  }

  --breadcrumb-height: 24px;
  --breadcrumb-radius: 12px;
}

.ion-color-ibf-no-alert-primary {
  --ion-color-base: var(--ion-color-ibf-no-alert-primary);
  --ion-color-base-rgb: var(--ion-color-ibf-no-alert-primary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-no-alert-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-no-alert-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-no-alert-primary-shade);
  --ion-color-tint: var(--ion-color-ibf-no-alert-primary-tint);
}

.ion-color-ibf-no-alert-secondary {
  --ion-color-base: var(--ion-color-ibf-no-alert-secondary);
  --ion-color-base-rgb: var(--ion-color-ibf-no-alert-secondary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-no-alert-secondary-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-ibf-no-alert-secondary-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-ibf-no-alert-secondary-shade);
  --ion-color-tint: var(--ion-color-ibf-no-alert-secondary-tint);
}

.ion-color-ibf-no-alert-tertiary {
  --ion-color-base: var(--ion-color-ibf-no-alert-tertiary);
  --ion-color-base-rgb: var(--ion-color-ibf-no-alert-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-no-alert-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-no-alert-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-no-alert-tertiary-shade);
  --ion-color-tint: var(--ion-color-ibf-no-alert-tertiary-tint);
}

.ion-color-ibf-trigger-alert-primary {
  --ion-color-base: var(--ion-color-ibf-trigger-alert-primary);
  --ion-color-base-rgb: var(--ion-color-ibf-trigger-alert-primary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-trigger-alert-primary-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-primary-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-ibf-trigger-alert-primary-shade);
  --ion-color-tint: var(--ion-color-ibf-trigger-alert-primary-tint);
}

.ion-color-ibf-trigger-alert-secondary {
  --ion-color-base: var(--ion-color-ibf-trigger-alert-secondary);
  --ion-color-base-rgb: var(--ion-color-ibf-trigger-alert-secondary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-trigger-alert-secondary-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-secondary-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-ibf-trigger-alert-secondary-shade);
  --ion-color-tint: var(--ion-color-ibf-trigger-alert-secondary-tint);
}

.ion-color-ibf-trigger-alert-tertiary {
  --ion-color-base: var(--ion-color-ibf-trigger-alert-tertiary);
  --ion-color-base-rgb: var(--ion-color-ibf-trigger-alert-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-trigger-alert-tertiary-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-ibf-trigger-alert-tertiary-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-ibf-trigger-alert-tertiary-shade);
  --ion-color-tint: var(--ion-color-ibf-trigger-alert-tertiary-tint);
}

.ion-color-ibf-primary {
  --ion-color-base: var(--ion-color-ibf-primary);
  --ion-color-base-rgb: var(--ion-color-ibf-primary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-primary-shade);
  --ion-color-tint: var(--ion-color-ibf-primary-tint);
}

.ion-color-ibf-secondary {
  --ion-color-base: var(--ion-color-ibf-secondary);
  --ion-color-base-rgb: var(--ion-color-ibf-secondary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-secondary-shade);
  --ion-color-tint: var(--ion-color-ibf-secondary-tint);
}

.ion-color-ibf-tertiary {
  --ion-color-base: var(--ion-color-ibf-tertiary);
  --ion-color-base-rgb: var(--ion-color-ibf-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-ibf-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-tertiary-shade);
  --ion-color-tint: var(--ion-color-ibf-tertiary-tint);
}

.ion-color-ibf-white {
  --ion-color-base: var(--ion-color-ibf-white);
  --ion-color-base-rgb: var(--ion-color-ibf-white-rgb);
  --ion-color-contrast: var(--ion-color-ibf-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-white-shade);
  --ion-color-tint: var(--ion-color-ibf-white-tint);
}

.ion-color-ibf-chat-not-self {
  --ion-color-base: var(--ion-color-ibf-chat-not-self);
  --ion-color-base-rgb: var(--ion-color-ibf-chat-not-self-rgb);
  --ion-color-contrast: var(--ion-color-ibf-chat-not-self-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-chat-not-self-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-chat-not-self-shade);
  --ion-color-tint: var(--ion-color-ibf-chat-not-self-tint);
}

.ion-color-ibf-chat-self {
  --ion-color-base: var(--ion-color-ibf-chat-self);
  --ion-color-base-rgb: var(--ion-color-ibf-chat-self-rgb);
  --ion-color-contrast: var(--ion-color-ibf-chat-self-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-chat-self-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-chat-self-shade);
  --ion-color-tint: var(--ion-color-ibf-chat-self-tint);
}

/** ----------------------------------- **/

.ion-color-ibf-black {
  --ion-color-base: var(--ion-color-ibf-black);
  --ion-color-base-rgb: var(--ion-color-ibf-black-rgb);
  --ion-color-contrast: var(--ion-color-ibf-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-black-shade);
  --ion-color-tint: var(--ion-color-ibf-black-tint);
}

.ion-color-ibf-grey {
  --ion-color-base: var(--ion-color-ibf-grey);
  --ion-color-base-rgb: var(--ion-color-ibf-grey-rgb);
  --ion-color-contrast: var(--ion-color-ibf-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ibf-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-ibf-grey-shade);
  --ion-color-tint: var(--ion-color-ibf-grey-tint);
}
