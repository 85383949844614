:root {
  --ion-color-fiveten-green-500: #368704;
  --ion-color-fiveten-green-500-rgb: 54, 135, 4;
  --ion-color-fiveten-green-500-contrast: #000000;
  --ion-color-fiveten-green-500-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-green-500-shade: #307704;
  --ion-color-fiveten-green-500-tint: #4a931d;
}

.ion-color-fiveten-green-500 {
  --ion-color-base: var(--ion-color-fiveten-green-500);
  --ion-color-base-rgb: var(--ion-color-fiveten-green-500-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-green-500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-green-500-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-green-500-shade);
  --ion-color-tint: var(--ion-color-fiveten-green-500-tint);
}
