:root {
  --ion-color-fiveten-green-700: #1d5701;
  --ion-color-fiveten-green-700-rgb: 29, 87, 1;
  --ion-color-fiveten-green-700-contrast: #ffffff;
  --ion-color-fiveten-green-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-green-700-shade: #1a4d01;
  --ion-color-fiveten-green-700-tint: #34681a;
}

.ion-color-fiveten-green-700 {
  --ion-color-base: var(--ion-color-fiveten-green-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-green-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-green-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-green-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-green-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-green-700-tint);
}
