:root {
  --ion-color-fiveten-red-100: #ffe3e3;
  --ion-color-fiveten-red-100-rgb: 255, 227, 227;
  --ion-color-fiveten-red-100-contrast: #000000;
  --ion-color-fiveten-red-100-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-red-100-shade: #e0c8c8;
  --ion-color-fiveten-red-100-tint: #ffe6e6;
}

.ion-color-fiveten-red-100 {
  --ion-color-base: var(--ion-color-fiveten-red-100);
  --ion-color-base-rgb: var(--ion-color-fiveten-red-100-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-red-100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-red-100-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-red-100-shade);
  --ion-color-tint: var(--ion-color-fiveten-red-100-tint);
}
