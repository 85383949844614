:root {
  --ion-color-fiveten-orange-700: #7a2d00;
  --ion-color-fiveten-orange-700-rgb: 122, 45, 0;
  --ion-color-fiveten-orange-700-contrast: #ffffff;
  --ion-color-fiveten-orange-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-orange-700-shade: #6b2800;
  --ion-color-fiveten-orange-700-tint: #87421a;
}

.ion-color-fiveten-orange-700 {
  --ion-color-base: var(--ion-color-fiveten-orange-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-orange-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-orange-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-orange-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-orange-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-orange-700-tint);
}
