:root {
  --ion-color-fiveten-purple-500: #ad92fe;
  --ion-color-fiveten-purple-500-rgb: 173, 146, 254;
  --ion-color-fiveten-purple-500-contrast: #000000;
  --ion-color-fiveten-purple-500-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-purple-500-shade: #9880e0;
  --ion-color-fiveten-purple-500-tint: #b59dfe;
}

.ion-color-fiveten-purple-500 {
  --ion-color-base: var(--ion-color-fiveten-purple-500);
  --ion-color-base-rgb: var(--ion-color-fiveten-purple-500-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-purple-500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-purple-500-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-purple-500-shade);
  --ion-color-tint: var(--ion-color-fiveten-purple-500-tint);
}
