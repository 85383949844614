:root {
  --ion-color-fiveten-neutral-500: #8a95a0;
  --ion-color-fiveten-neutral-500-rgb: 138, 149, 160;
  --ion-color-fiveten-neutral-500-contrast: #000000;
  --ion-color-fiveten-neutral-500-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-neutral-500-shade: #79838d;
  --ion-color-fiveten-neutral-500-tint: #96a0aa;
}

.ion-color-fiveten-neutral-500 {
  --ion-color-base: var(--ion-color-fiveten-neutral-500);
  --ion-color-base-rgb: var(--ion-color-fiveten-neutral-500-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-neutral-500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-neutral-500-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-neutral-500-shade);
  --ion-color-tint: var(--ion-color-fiveten-neutral-500-tint);
}
