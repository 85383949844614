:root {
  --ion-color-fiveten-purple-300: #d0c0ff;
  --ion-color-fiveten-purple-300-rgb: 208, 192, 255;
  --ion-color-fiveten-purple-300-contrast: #000000;
  --ion-color-fiveten-purple-300-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-purple-300-shade: #b7a9e0;
  --ion-color-fiveten-purple-300-tint: #d5c6ff;
}

.ion-color-fiveten-purple-300 {
  --ion-color-base: var(--ion-color-fiveten-purple-300);
  --ion-color-base-rgb: var(--ion-color-fiveten-purple-300-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-purple-300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-purple-300-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-purple-300-shade);
  --ion-color-tint: var(--ion-color-fiveten-purple-300-tint);
}
