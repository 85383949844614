:root {
  --ion-color-fiveten-yellow-700: #665606;
  --ion-color-fiveten-yellow-700-rgb: 102, 86, 6;
  --ion-color-fiveten-yellow-700-contrast: #ffffff;
  --ion-color-fiveten-yellow-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-yellow-700-shade: #5a4c05;
  --ion-color-fiveten-yellow-700-tint: #75671f;
}

.ion-color-fiveten-yellow-700 {
  --ion-color-base: var(--ion-color-fiveten-yellow-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-yellow-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-yellow-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-yellow-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-yellow-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-yellow-700-tint);
}
