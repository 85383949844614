:root {
  --ion-color-fiveten-yellow-500: #ffd601;
  --ion-color-fiveten-yellow-500-rgb: 255, 214, 1;
  --ion-color-fiveten-yellow-500-contrast: #000000;
  --ion-color-fiveten-yellow-500-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-yellow-500-shade: #e0bc01;
  --ion-color-fiveten-yellow-500-tint: #ffda1a;
}

.ion-color-fiveten-yellow-500 {
  --ion-color-base: var(--ion-color-fiveten-yellow-500);
  --ion-color-base-rgb: var(--ion-color-fiveten-yellow-500-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-yellow-500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-yellow-500-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-yellow-500-shade);
  --ion-color-tint: var(--ion-color-fiveten-yellow-500-tint);
}
