:root {
  --ion-color-fiveten-purple-700: #4f22d7;
  --ion-color-fiveten-purple-700-rgb: 79, 34, 215;
  --ion-color-fiveten-purple-700-contrast: #ffffff;
  --ion-color-fiveten-purple-700-contrast-rgb: 255, 255, 255;
  --ion-color-fiveten-purple-700-shade: #461ebd;
  --ion-color-fiveten-purple-700-tint: #6138db;
}

.ion-color-fiveten-purple-700 {
  --ion-color-base: var(--ion-color-fiveten-purple-700);
  --ion-color-base-rgb: var(--ion-color-fiveten-purple-700-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-purple-700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-purple-700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-purple-700-shade);
  --ion-color-tint: var(--ion-color-fiveten-purple-700-tint);
}
