:root {
  --ion-color-fiveten-purple-100: #eeebff;
  --ion-color-fiveten-purple-100-rgb: 238, 235, 255;
  --ion-color-fiveten-purple-100-contrast: #000000;
  --ion-color-fiveten-purple-100-contrast-rgb: 0, 0, 0;
  --ion-color-fiveten-purple-100-shade: #d1cfe0;
  --ion-color-fiveten-purple-100-tint: #f0edff;
}

.ion-color-fiveten-purple-100 {
  --ion-color-base: var(--ion-color-fiveten-purple-100);
  --ion-color-base-rgb: var(--ion-color-fiveten-purple-100-rgb);
  --ion-color-contrast: var(--ion-color-fiveten-purple-100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fiveten-purple-100-contrast-rgb);
  --ion-color-shade: var(--ion-color-fiveten-purple-100-shade);
  --ion-color-tint: var(--ion-color-fiveten-purple-100-tint);
}
